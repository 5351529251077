import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { initGA } from './utils/analytics';
import LoadingFallback from './Components/common/LoadingFallback';
import { routes } from './routes/routes'; // Import centralized routes


const HomePage = lazy(() => import('./pages/HomePage'));
const NewDemoPage = lazy(() => import('./pages/NewDemoPage'))
const SecretDemoPage = lazy(() => import('./pages/SecretDemoPage'))
const SimonLelandDemoPage = lazy(() => import('./pages/SimonLelandDemoPage'))
const Comparison = lazy(() => import('./pages/Comparison'))
function App() {

  useEffect(() => {
    initGA();
  }, []);

  return (
    <Router>
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          <Route path={routes.home} element={<HomePage />} />
          <Route path={routes.demo} element={<NewDemoPage />} />
          <Route path={routes.secret} element={<SecretDemoPage />} />
          <Route path={routes.simonleland} element={<SimonLelandDemoPage />} />
          <Route path={routes.secretdemo2} element={<SimonLelandDemoPage />} />
          <Route path={routes.comparison} element={<Comparison />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
export default App;
